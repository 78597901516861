<template>
  <table class="table table-sm" :class="{ 'table-responsive': $store.state.display.sm }">
    <thead>
      <tr>
        <th></th>
        <th>PRODUCTO</th>
        <th class="text-center">CANTIDAD</th>
        <th class="text-right">PRECIO UNITARIO</th>
        <th class="text-right">SUBTOTAL</th>
        <th v-show="!readonly"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(l, i) in products" :key="l.product_id">
        <td>
          <app-img-viewer v-if="l.product.file_id" :fileId="l.product.file_id"></app-img-viewer>
        </td>
        <td>{{ l.product.name }}</td>
        <td class="text-center">{{ l.quantity }}</td>
        <td class="text-right">
          <app-span-money :quantity="l.unit_price" :moneyCode="l.money_code"></app-span-money>
        </td>
        <td class="text-right">
          <app-span-money :quantity="l.unit_price * l.quantity" :moneyCode="l.money_code"></app-span-money>
        </td>
        <td v-show="!readonly">
          <button class="btn btn-sm btn-light" @click="products.splice(i, 1)">
            <i class="fa fa-trash"></i>
          </button>
        </td>
      </tr>
      <tr>
        <th></th>
        <th class="text-right" colspan="3">TOTAL</th>
        <th class="text-right">
          <app-span-money :quantity.sync="total" :moneyCode="pay_money_code"></app-span-money>
        </th>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    products: {
      required: true
    },
    pay_money_code: {
      required: true
    },
    payment_method_id: {
      default: 1
    },
    readonly: {
      default: false
    }
  },
  computed: {
    total() {
      let total = 0;
      for (let i of this.products) {
        if (this.pay_money_code == i.money_code)
          total += parseFloat(i.unit_price) * parseFloat(i.quantity);
        // else {
        //   change_rate = this.$store.state.admin.getChangeRateTo(i.money_code, this.pay_money_code)
        //   total +=
        // }
      }
      // return round(total, this.payment_method_id == 1 ? 1 : 2);
      return total;
    },
    change_rate() {
      return this.$store.state.admin.change_rate;
    }
  },
  methods: {
    // round(val) {
    //   return round(val, this.payment_method_id == 1 ? 1 : 2);
    // }
  }
};
</script>

<style>
</style>
