<template>
  <div>
    <div class="row">
      <div class="col">
        <h4>REGISTRAR VENTA</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="form-group">
          <button class="btn btn-secondary" @click="reset()">Reiniciar</button>
        </div>
        <div class="form-group">
          <label for>Punto de Atención</label>
          <SelectStore ref="selSto" v-model="store_id"></SelectStore>
        </div>
        <div
          class="form-group"
          v-show="config ? config.sales.optional_client : true"
        >
          <app-checkbox
            v-model="setClient"
            placeholder="Registrar Cliente"
            id="56456345"
          ></app-checkbox>
        </div>
        <div class="form-group">
          <label for>Cliente</label>
          <SelectClient
            :disabled="!setClient"
            ref="selcli"
            v-model="reg.client_id"
          ></SelectClient>
        </div>
        <div class="form-group" v-if="$refs.selcli && $refs.selcli.val">
          <label for>Cuentas del cliente</label>

          <p
            class="text-muted"
            v-for="l in $refs.selcli.val.accounts"
            :key="l.money_code"
          >
            {{ moneyList[l.money_code].name }}
            <app-span-money
              :moneyCode="l.money_code"
              :quantity="l.quantity"
            ></app-span-money>
          </p>
        </div>
        <div class="form-group">
          <button
            :disabled="!setClient"
            class="btn btn-primary"
            @click="
              $refs.formClient.reset();
              $refs.dFormClient.show();
            "
          >
            <i class="fa fa-plus"></i> Nuevo Cliente
          </button>
        </div>
      </div>
      <div class="col-sm-4">
        <SetProduct
          @pCodeFocusout="$refs.inMoPay.focusToQuantity()"
          ref="setPro"
          class="mb-3"
          @submit="addProduct($event)"
        ></SetProduct>
      </div>

      <div class="col-sm-4">
        <form @submit.prevent="preSave()">
          <div class="form-group">
            <label for>Medio de pago *</label>
            <app-select-payment-method
              v-model="reg.payment_method_id"
            ></app-select-payment-method>
          </div>
          <div class="form-group">
            <label for>Dinero recibido*</label>
            <app-input-money
              :required="true"
              :moneyCode.sync="pay_money_code"
              :quantity.sync="pay"
              :byKeyPress="true"
              ref="inMoPay"
            ></app-input-money>
          </div>
          <div class="form-group">
            <label for>Vuelto</label>
            <app-input-money
              :required="true"
              :moneyCode.sync="pay_money_code"
              :quantity.sync="pay_change"
            ></app-input-money>
          </div>
          <div class="form-group">
            <label for="">Enpaquetado</label>
            <select v-model="type_id" class="custom-select">
              <option :value="1">PARA LA MESA</option>
              <option :value="2">PARA LLEVAR</option>
            </select>
          </div>
          <div class="form-group">
            <app-button-submit :debounce="0">
              Registrar Venta
            </app-button-submit>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <ListPreSale
          :products="products"
          :payment_method_id="reg.payment_method_id"
          ref="lispre"
          :pay_money_code="pay_money_code"
        ></ListPreSale>
      </div>
    </div>

    <app-modal-basic ref="dFormClient">
      <FormClient
        ref="formClient"
        @submitted="
          $refs.selcli.setValueFromId($event);
          $refs.dFormClient.hide();
        "
      ></FormClient>
    </app-modal-basic>

    <app-modal-basic ref="dPreSale">
      <h4>VISTA PREVIA</h4>
      <div
        class="form-group"
        v-if="setClient && this.$refs.selcli && this.$refs.selcli.val"
      >
        <label for>Cliente</label>
        <input
          type="text"
          class="form-control"
          disabled
          :value="this.$refs.selcli.val.name"
        />
      </div>
      <ListPreSale
        :products="products"
        :payment_method_id="reg.payment_method_id"
        :pay_money_code="pay_money_code"
        :readonly="true"
      ></ListPreSale>
      <div class="form-group">
        <label for>Medio de pago *</label>
        <app-select-payment-method
          :disabled="true"
          v-model="reg.payment_method_id"
        ></app-select-payment-method>
      </div>
      <div class="form-group">
        <label for>Dinero Recibido:</label>
        <strong>
          <app-input-money
            :moneyCode="pay_money_code"
            :quantity="pay"
            :disabled="true"
          ></app-input-money>
        </strong>
      </div>
      <div class="form-group">
        <label for>Vuelto:</label>
        <strong>
          <app-input-money
            :moneyCode="pay_money_code"
            :quantity="pay_change"
            :disabled="true"
          ></app-input-money>
        </strong>
      </div>
      <a
        v-if="sale_id"
        :href="backendUrl + 'print/store/sales-ticket/' + sale_id"
        class="btn btn-light"
      >
        <i class="fa fa-print"></i> Imprimir Ticket
      </a>
      <app-button-submit v-else class="mr-1" @click="sale()"
        >Continuar</app-button-submit
      >
      <div class="form-group mt-2" v-if="errMsg.length > 0">
        <div class="alert alert-danger">{{ errMsg }}</div>
      </div>
    </app-modal-basic>

    <!-- <Snackbar ref="sb" :holdTime="100000" :wrapClass="sb"></Snackbar> -->
    <app-modal-basic ref="mbAlert">
      <div class="alert alert-warning">
        <h4>Advertencia</h4>
        El precio del producto no esta en la moneda de la operacion, por tanto
        no se puede agregar
      </div>
      <button class="btn btn-secondary" @click="$refs.mbAlert.hide()">
        Ok
      </button>
    </app-modal-basic>
  </div>
</template>

<script>
import SetProduct from "./SetProduct";
import SelectClient from "../clients/Select";
import FormClient from "../clients/Form";
import { round } from "lodash";
import ListPreSale from "./ListPreSale";
import SelectStore from "../../logistic-module/stores/Select";
import { StoreService } from "../service";

export default {
  components: {
    SetProduct,
    SelectClient,
    FormClient,
    ListPreSale,
    SelectStore
    // Snackbar
  },
  data() {
    return {
      type_id: 1,
      backendUrl: process.env.VUE_APP_API_URL,
      errMsg: "",
      reg: {
        pay_money_code: this.$store.state.config.money.default,
        payment_method_id: 1,
        pay: 0
      },
      store_id: undefined,
      products: [],
      setClient: true,
      pay: 0,
      pay_change: 0,
      pay_money_code: this.$store.state.config.money.default,
      sale_id: undefined
    };
  },
  watch: {
    pay(to) {
      this.reg.pay = to;
      this.setChange(to);
    },
    pay_change(to) {
      this.reg.pay_change = to;
    },
    pay_money_code(to) {
      this.reg.pay_money_code = to;
      this.products = [];
    }
  },
  computed: {
    config() {
      return this.$store.state.config.store;
    },
    total() {
      return this.$refs.lispre.total;
    },
    moneyList() {
      return this.$store.state.config.money
        ? this.$store.state.config.money.options
        : [];
    }
  },
  mounted() {
    this.$refs.selSto.setValueFromId(this.$store.state.user.store_id);
    // this.store_id = this.$store.state.user.store_id;
  },
  methods: {
    reset() {
      (this.setClient = true),
        (this.reg = {
          pay_money_code: this.$store.state.config.money.default,
          payment_method_id: 1,
          client_id: null
        });
      this.errMsg = "";
      this.products = [];
      this.pay = 0;
      this.pay_change = 0;
      this.pay_money_code = this.$store.state.config.money.default;
      this.sale_id = undefined;
      this.$refs.setPro.reset();
      this.$refs.selcli.reset();
    },
    setChange(to) {
      let preChange = round(to - this.total, 2);
      this.pay_change = preChange > 0 ? preChange : 0;
    },
    sale() {
      this.reg.pay_change = this.pay_change;
      StoreService.saveSale({
        ...this.reg,
        products: this.products,
        store_id: this.store_id,
        type_id: this.type_id
      }).then(
        res => {
          this.errMsg = "";
          this.sale_id = res.sale.id;
        },
        err => {
          // console.log({ err: err.response });
          this.errMsg = err.response.data.message;
        }
      );
    },
    preSave() {
      this.$refs.dPreSale.show();
    },
    addProduct(item) {
      if (item.money_code == this.pay_money_code) {
        let lRes = this.products.filter(x => x.product_id == item.product_id);
        if (lRes.length == 0) {
          this.products.push(item);
        } else {
          lRes[0].quantity += parseInt(item.quantity);
        }
      } else {
        this.$refs.mbAlert.show();
      }
    }
  }
};
</script>

<style></style>
