<template>
  <form @submit.prevent="addProduct()">
    <div class="form-group">
      <label for>Producto*</label>
      <SelectProduct v-show="!faster" ref="selpro" v-model="product_id"></SelectProduct>
      <SelProByCode v-model="product_id" @focusout="blinkEvent($event)" ref="selbycod" v-show="faster"></SelProByCode>
    </div>
    <div class="form-group">
      <label for>Precio Unitario*</label>
      <app-input-money :quantity.sync="reg.unit_price" :moneyCode.sync="reg.money_code"></app-input-money>
      <p v-show="pay_money_code != reg.org_money_code">
        <app-span-money :quantity="reg.org_unit_price" :moneyCode="reg.org_money_code"></app-span-money>
      </p>
    </div>
    <div class="form-group">
      <label for>Cantidad*</label>
      <input type="number" class="form-control" required v-model="reg.quantity">
    </div>
    <div class="form-group">
      <label for>Descuento</label>
      <div class="input-group">
        <select v-model="reg.discount" class="custom-select" required v-if="discountSelect">
          <option value="0">Sin descuento</option>
          <option value="5">5%</option>
          <option value="10">10%</option>
          <option value="15">15%</option>
          <option value="20">20%</option>
          <option value="25">25%</option>
          <option value="30">30%</option>
          <option value="35">35%</option>
          <option value="40">40%</option>
        </select>
        <input type="number" step="1" class="form-control" v-model="reg.discount" required v-else>
        <div class="input-group-append">
          <button
            class="btn btn-light"
            style="z-index: 0;"
            @click.prevent="discountSelect = !discountSelect"
          >
            <i class="fa fa-exchange-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="form-group">
      <app-checkbox v-model="faster" placeholder="Rapido" id="toFaster"></app-checkbox>  
    </div>
    <app-button-submit :debounce="0">
      <i class="fa fa-plus"></i> Agregar
    </app-button-submit>
  </form>
</template>

<script>
import SelectProduct from "../products/Select";
import SelProByCode from "../products/SelectorByCode";

export default {
  components: {
    SelectProduct,
    SelProByCode
  },
  props: {
    pay_money_code: {
      required: false
    }
  },
  data() {
    return {
      faster: false,
      product_id: undefined,
      reg: {
        discount: 0,
        quantity: 1
      },
      discountSelect: true
    };
  },
  mounted() {},
  watch: {
    product_id(to) {
      this.reg.product_id = to;
      if (to) {
        this.reg.unit_price = this.productSelected.unit_price;
        this.reg.org_unit_price = this.productSelected.unit_price;

        this.reg.money_code = this.productSelected.money_code;
        this.reg.org_money_code = this.productSelected.money_code;
      }

      if(this.faster){
        this.addProduct()
        this.reset();
      }
    }
  },
  computed: {
    productSelected() {
      return this.faster ? this.$refs.selbycod.val : this.$refs.selpro.val;
    }
  },
  methods: {
    blinkEvent(e) {
      // console.log('BLINK', e)
      this.$emit('pCodeFocusout', e)
    },
    reset() {
      // this.product_id = null;
      this.$refs.selpro.reset();
      this.$refs.selbycod.reset();
      this.reg.discount = 0;
      this.reg = {
        discount: 0,
        quantity: 1,
        // unit_price: 0
      }
      // this.unit_price = 0
      // this.money_code = undefined
      // this.quantity = 1
      // this.discount = 0
      this.discountSelect = true;
    },
    addProduct() {
      if (this.product_id) {
        var discount = (this.reg.unit_price * this.reg.discount) / 100;
        let item = JSON.parse(JSON.stringify(this.reg));
        item.unit_price = item.unit_price - discount;
        item.product = this.productSelected;
        this.$emit("submit", item);
      }
    }
  }
};
</script>

<style>
</style>
